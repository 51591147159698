import React, { useEffect } from "react";
import algoliasearch from "algoliasearch/lite";
import {orderBy} from 'lodash';
import {
  InstantSearch,
  connectInfiniteHits,
  Configure,
  connectRefinementList,
  connectCurrentRefinements,
} from "react-instantsearch-dom";
import BlockLayout from "../../blocks/BlockLayout";
import {
  StyledWorkCard,
  WorkWrapper,
  FilterWrapper,
  StyledFilterLabel,
} from "./style";

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
);

const Hit = ({ hit }) => {
  return (
    <StyledWorkCard
      className={hit.permalink}
      post={{
        title: hit.post_title,
        uri: hit.uri,
        featuredImage: hit.images.thumbnail,
        relatedContent: hit.relatedContent
      }}
    />
  );
};

const ClearRefinements = ({ items, refine }) => (
  <StyledFilterLabel
    onClick={() => refine(items)}
    className="filterLabel"
    isRefined={!items.length}
  >
    All
  </StyledFilterLabel>
);

const CustomClearRefinements = connectCurrentRefinements(ClearRefinements);

const InfiniteHits = ({ hits, hasMore, refineNext }) => {
  let sentinal = null;
  const onSentinelIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && hasMore) {
        refineNext();
      }
    });
  };
  useEffect(() => {
    let observer = new IntersectionObserver(onSentinelIntersection);
    observer.observe(sentinal);
    return function cleanup() {
      observer.disconnect();
    };
  });
  return (
    <WorkWrapper>
      {hits.map((hit) => (
        <Hit hit={hit} />
      ))}
      <div ref={(c) => (sentinal = c)} />
    </WorkWrapper>
  );
};

const CustomInfiniteHits = connectInfiniteHits(InfiniteHits);

const RefinementList = ({ items, createURL, refine }) => (
  <FilterWrapper>
    <CustomClearRefinements />
    {items.map((item) => (
      <StyledFilterLabel
        key={item.label}
        href={createURL(item.value)}
        className="filterLabel"
        onClick={(event) => {
          event.preventDefault();
          refine(item.value);
        }}
        {...item}
      >
        {item.label} <sup>{item.count}</sup>
      </StyledFilterLabel>
    ))}
  </FilterWrapper>
);

const CustomRefinementList = connectRefinementList(RefinementList);

const WorkSearch = ({ data }) => {
  return (
    <BlockLayout>
      <InstantSearch
        indexName={`${process.env.GATSBY_ALGOLIA_PREFIX}searchable_posts`}
        searchClient={searchClient}
      >
        <Configure filters="post_type:work" />
        <CustomRefinementList attribute="taxonomies.workcategory"
        transformItems={items =>
          orderBy(items, ['label', 'count'], ['asc', 'desc'])
        }
        />
        <CustomInfiniteHits />
      </InstantSearch>
    </BlockLayout>
  );
};

export default WorkSearch;

import styled from 'styled-components'
import breakpoints from 'styled-components-breakpoint';
import WorkCard from '../WorkCard';
import FilterLabel from '../FilterLabel';

export const StyledFilterLabel = styled(FilterLabel)``;

export const FilterWrapper = styled.div`
    margin-bottom: 50px;
    ${StyledFilterLabel} {
        margin-right: 8px;
        margin-bottom: 16px;
    }
`;

export const StyledWorkCard = styled(WorkCard)``;

export const WorkWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    ${breakpoints('sm')`
        grid-template-columns: 1fr 1fr;
    `}
    ${breakpoints('md')`
        grid-template-columns: 1fr 1fr 1fr;
    `}
    ${breakpoints('lg')`
        grid-template-columns: 1fr 1fr 1fr 1fr;
    `}
    column-gap: 16px;
    row-gap: 16px;
    ${StyledWorkCard} {
    }
`;
import React from "react";
import { Label } from "./style";

const FilterLabel = ({ children, ...other }) => {
  return (
    <Label {...other}>
      {children}
    </Label>
  );
};

export default FilterLabel;

import React from "react";
import { graphql } from "gatsby";
import RenderBlocks from "../../blocks/renderBlocks";
import PageLayout from "../../layout/PageLayout";
import BlockLayout from "../../blocks/BlockLayout";
import WorkSearch from "../../components/WorkSearch";

const page = ({ data }) => {
  const { page } = data;
  const {
    title,
    pageOptions,
    contentBlocks: { blocks },
  } = page;
  return (
    <PageLayout data={data} headerPosition='sticky'>
        {pageOptions ? !pageOptions.hidePageTitle && <BlockLayout heading={title} pageTitle={true} /> : <BlockLayout heading={title} pageTitle={true} />}
        <WorkSearch />
        <RenderBlocks blocks={blocks} />
    </PageLayout>
  );
};

export default page;

export const query = graphql`
  query WorkPage($id: String!) {
    page: wpContentNode(id: { eq: $id }) {
      ... on WpPage {
        ...PageContent
      }
    }
  }
`;

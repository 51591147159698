import styled from 'styled-components'

export const Label = styled.a`
        font-size: ${props => props.theme.text.small.fontSize};
        font-weight: ${props => props.theme.text.small.fontWeight};
        display: inline-block;
        border: 1px solid ${props => props.theme.colors.primary};
        padding: 0.45em 0.8em 0.2em 0.5em;
        border-radius: 1.5em;
        color: ${props => props.theme.colors.highlight};
        vertical-align: baseline;
        transition: 0.2s;
        cursor: pointer;
        &:before {
            content: '';
            display: inline-block;
            vertical-align: baseline;
            height: 0.7em;
            width: 0.7em;
            border-radius: 1em;
            border: 1px solid ${props => props.isRefined ? 'currentColor' : props.theme.colors.primary};
            background-color: ${props => props.isRefined ? 'currentColor' : 'transparent'};
            margin-right: 0.5em;
        }
        &:hover {
            background-color: ${props => props.theme.colors.highlight};
            color: ${props => props.theme.colors.highlightText};
            border-color: currentColor;
        }
        sup {
            color: ${props => props.theme.colors.tertairy};
        }
    `